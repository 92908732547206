<template>
  <el-dialog v-model="dialogFormVisible" :title="title" width="500px" @close="close">
    <el-form ref="formRef" label-width="80px" :model="form" :rules="rules">
      <el-form-item label="父节点" prop="deptParentName">
        <!--  
          :disabled="!form.deptParentId"-->
        <el-select v-model="form.deptParentName" ref="configSelect" placeholder="请选择父节点">
          <el-option :label="form.deptName" style="height: auto; padding: 0" :value="form.deptId">
            <el-tree ref="treeRef" :data="treeData" default-expand-all :props="defaultProps" @node-click="handleNodeClick" />
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="名称" prop="deptName">
        <el-input v-model="form.deptName" />
      </el-form-item>
      <el-form-item label="排序" prop="sortNum">
        <el-input v-model="form.sortNum" type='number' />
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-radio-group v-model="form.status">
          <el-radio :label="0">正常</el-radio>
          <el-radio :label="1">停用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input v-model="form.remarks" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { defineComponent, getCurrentInstance, reactive, toRefs } from 'vue'
import {
  addDepartment,
  getDeptTree,
  upDateDepartment,
} from '@/api/departmentManagement'

export default defineComponent({
  name: 'DepartmentManagementEdit',
  emits: ['fetch-data'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance()

    const state = reactive({
      formRef: null,
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'deptName',
      },
      form: {
        deptName: '',
        deptParentId: '',
        deptParentName: '',
        deptId: '',
        remarks: '',
      },
      rules: {
        deptParentName: [
          { required: true, trigger: 'blur', message: '请选择父节点' },
        ],
        deptName: [{ required: true, trigger: 'blur', message: '请输入名称' }],
        sortNum: [{ required: true, trigger: 'blur', message: '请输入排序' }],
        status: [{ required: true, trigger: 'change', message: '请选择状态' }],
      },
      title: '',
      dialogFormVisible: false,
    })

    const fetchData = async () => {
      let { data } = await getDeptTree()
      let originDept = {
        children: [],
        deptId: 0,
        deptName: '顶级部门',
        deptParentId: 0,
      }
      data.unshift(originDept)
      state.treeData = data
    }
    const handleNodeClick = (node) => {
      console.log(node)
      state.form.deptParentName = node.deptName
      state.form.deptParentId = node.deptId
      proxy.$refs.configSelect.blur()
    }
    const showEdit = (row) => {
      fetchData()
      if (!row) {
        state.title = '添加'
      } else {
        state.title = '编辑'
        row.deptParentName = row.deptParentName
          ? row.deptParentName
          : '顶级部门'
        state.form = Object.assign({}, row)
      }
      state.dialogFormVisible = true
    }
    const close = () => {
      state['formRef'].resetFields()
      state.form = {
        deptName: '',
        deptParentId: '',
      }
      state.dialogFormVisible = false
    }
    const save = () => {
      state['formRef'].validate(async (valid) => {
        if (valid) {
          let msg = ''
          if (state.title == '添加') {
            let data = await addDepartment(state.form)
            msg = data.msg
          } else {
            let data = await upDateDepartment(state.form)
            msg = data.msg
          }
          proxy.$baseMessage(msg, 'success', 'vab-hey-message-success')
          emit('fetch-data')
          close()
        }
      })
    }

    fetchData()

    return {
      ...toRefs(state),
      handleNodeClick,
      showEdit,
      close,
      save,
    }
  },
})
</script>
